import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { parseError } from "./helpers"

/**
 * Рассчитываем квитанцию со всеми скидками и прочим
 */
export const calcInvoiceService = async (order, invoice) => {
    try {
        let data = {...invoice};
        data['order'] = {invoices: [...order.invoices]};
        let response = await $api.post(endpoints.invoice.calc.replace("{orderId}", order.id), data);
        if (response.data.success == true) {
            if (response.data.total) {
                return response.data;
            }
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}

/**
 * Добавляем скидку к квитанции
 */
 export const saveInvoiceService = async (order, invoice) => {
    try {
        let response = await $api.post(endpoints.invoice.save.replace("{orderId}", order.id).replace("{invoiceId}", invoice.id), invoice);
        if (response.data.success == true) {
            return true;
        }
        console.log(response);
        throw parseError(response);
    } catch(error) {
        console.log(error.response);
        if (error.response) {
            throw parseError(error.response);
        } else {
            throw parseError(error);
        }
    }
}