import { $api } from "@/plugins/Axios"
import endpoints from "./endpoints"
import { parseError } from "./helpers"

/**
 * Запрос на авторизацию
 */
export const registryService = async (request) => {
    try {
        let response = await $api.get(endpoints.registry.get, { 
            params:{
                ...request,
            }
        });
        if (response.data.result == 'success') {
            return response.data;
        }
        throw parseError(response);
    } catch(error) {
        throw parseError(error.response);
    }
}