/**
 * Вспомогательные функции для заказа
 */
export default  {
  data() {
    return {
    }
  },
  methods: {
    /**
     * Метка типа
     * @param string type 
     * @returns 
     */
    getTypeLabel(type) {
      switch(type) {
        case 'acceptance': return 'Приемка';
        case 'delivery': return 'Доставка';
      }
      return 'Доставка';
    },
    /**
     * Метка статуса
     * @param string status 
     * @returns 
     */
    getStatusLabel(status) {
      switch(status) {
        case 'new': return 'Новый';
        case 'drive': return 'В пути';
        case 'inplace': return 'Приехал';
        case 'transfer': return 'Передача';
        case 'move': return 'Перенос';
        case 'partial': return 'Выполнен частично';
        case 'cancel': return 'Отменен';
        case 'complete': return 'Выполнен';
      }
      return 'Неизвестный';
    },
    /**
     * Получаем статус товара
     * @param string status 
     */
    getProductStatusLabel(status) {
      switch(status) {
        case 'new': return 'Новый';
        case 'accepted': return 'Принята';
        case 'given_away': return 'Выдана';
        case 'avoid': return 'Не получена';
        case 'not_given': return 'Не выдана';
      }
      return 'Неизвестный';
    },
    /**
     * Получаем статус квитанции
     * @param string status 
     */
     getInvoiceStatusLabel(status) {
      switch(status) {
        case 'new': return 'Новый';
        case 'accepted': return 'Принята';
        case 'given_away': return 'Выдана';
        case 'not_given': return 'Не выдана';
        case 'avoid': return 'Не получена';
        case 'rework': return 'Доработка';
      }
      return 'Неизвестный';
    },
    /**
     * Метка статуса
     * @param string status 
     * @returns 
     */
     getStatusColor(status) {
      switch(status) {
        case 'new': return 'success';
        case 'drive': return 'warning';
        case 'cancel': return 'danger';
        case 'move': return 'danger';
        case 'avoid': return 'danger';
        case 'complete': return 'medium';
        case 'partial': return 'medium';
      }
      return 'primary';
    }
  }
}